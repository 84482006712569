const { dateAtOffset, getDateString } = require("../utils/date-utils");

const common = {
    email: {
        maxLength: 254,
    },
    antiSpam: {
        answers: ["no", "ne"],
        inputs: {
            answer: "name_a8651d075c354d49",
            empty: "email_a8651d075c354d49",
        }
    },
}

const bookingForm = {
    fullName: {
        minLength: 1,
        maxLength: 1000,
    },
    date: {
        // Uses UTC+1 to estimate time in Prague. This ignores daylight savings
        // (UTC+2 in summer), but min and max date is generous anyway...
        // In the worst case the time can be off by 1 hour.
        min: () => getDateString(dateAtOffset(new Date(), 1)),
        max: () => {
            const today = new Date();
            const maxDate = new Date(
                today.getFullYear() + 2,
                today.getMonth(),
                today.getDate()
            );
            return getDateString(dateAtOffset(maxDate, 1));
        },
    },
    message: {
        maxLength: 10_000,
    },
}

const contactForm = {
    name: {
        minLength: 1,
        maxLength: 800,
    },
    message: {
        minLength: 1,
        maxLength: 10_000,
    },
}

module.exports = {
    common,
    bookingForm,
    contactForm,
}
