function dateAtOffset(date, timezoneOffsetH) {
    return new Date(date.getTime() + (timezoneOffsetH * 3_600_000));
}

function formatDateLocal(date, locale=[]) {
    return (date instanceof Date ? date : new Date(date)).toLocaleDateString(locale);
}

function formatTimeLocal(time, locale=[]) {
    return (time instanceof Date ? time : new Date(`2020-01-01T${time}`)).toLocaleTimeString(locale, { hour: "2-digit", minute: "2-digit"});
}

function getDateString(date) {
    return date.toISOString().split('T')[0];
}

function ignoreTime(date) {
    const newDate = new Date(date.getTime());
    newDate.setHours(0, 0, 0, 0);
    return new Date(newDate);
}

function isDate(value) {
    return isFinite(
        (value instanceof Date)
            ? value
            : new Date(value)
    );
}

function normalizeDate(value) {
    if(!isDate(value)) return null;
    const date = (value instanceof Date) ? value : new Date(value);
    return date.toISOString().split("T")[0];
}

function isTime(str, regExp = /^\d{2}:\d{2}$/) {
    return regExp.test(str);
}

function parseTime(str, regExp = /^(\d{2}):(\d{2})$/) {
    const [hours, mins] = regExp.exec(str).slice(1).map(n => parseInt(n, 10));
    return { h: hours, m: mins };
}

function isTimeAfter(minTime, time) {
    return (time.h > minTime.h) ||
        (time.h === minTime.h && time.m >= minTime.m);
}

function isTimeBefore(maxTime, time) {
    return (time.h < maxTime.h) ||
        (time.h === maxTime.h && time.m <= maxTime.m);
}

function isTimeBetween(minTime, maxTime, time) {
    return isTimeAfter(minTime, time) && isTimeBefore(maxTime, time);
}

module.exports.dateAtOffset = dateAtOffset;
module.exports.ignoreTime = ignoreTime;
module.exports.getDateString = getDateString;
module.exports.formatDateLocal = formatDateLocal;
module.exports.formatTimeLocal = formatTimeLocal;

module.exports.isDate = isDate;
module.exports.normalizeDate = normalizeDate;
module.exports.isTime = isTime;
module.exports.parseTime = parseTime;
module.exports.isTimeAfter = isTimeAfter;
module.exports.isTimeBefore = isTimeBefore;
module.exports.isTimeBetween = isTimeBetween;