function formatDate(locale, date) {
    const realDate = typeof(date) === "string" ? new Date(date) : date;
    return realDate.toLocaleDateString(locale,
        { year: 'numeric', month: 'long', day: 'numeric' }
    );
}

function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function list(items, formatKey = k => k, formatValue = (k, v) => v, formatItem = (k, v) => `${k}: ${v}`) {
    return Object.entries(items)
        .map(([k, v]) => formatItem(formatKey(k), formatValue(k, v)))
        .join("\n").trimEnd();
}

function formFieldsList(items, translations, exclude) {
    const included = Object.fromEntries(
        (items !== null && items !== undefined)
            ? Object.entries(items).filter(([name]) => !exclude.includes(name))
            : []
    );

    return list(included,
        key => translations.hasOwnProperty(key)
            ? translations[key]
            : capitalize(key.replace("-", " ")),
        (key, value) => translations.hasOwnProperty(`${key}_values`)
            ? translations[`${key}_values`][value]
            : value,
    );
}

function optional(title, value, separator = ": ", valueTransform = (str) => `"${str}"`) {
    return (value === null || value === undefined || value === "")
        ? ""
        : `${title}${separator}${valueTransform(value)}`;
}

function joinWithLastSeparator(values, separator, lastSeparator) {
    if (values.length === 0) return "";
    if (values.length === 1) return values[0];
    return values.slice(0, -1).join(separator) + lastSeparator + values.slice(-1);
}

function enumerate(values, conjunction, separator = ",") {
    return joinWithLastSeparator(values, `${separator} `, ` ${conjunction} `)
}

module.exports.d = require("dedent");
module.exports.capitalize = capitalize;
module.exports.optional = optional;
module.exports.list = list;
module.exports.enumerate = enumerate;
module.exports.formFieldsList = formFieldsList;
module.exports.formatDate = formatDate;