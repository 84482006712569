import React from "react";
import { useCallback } from "react";
import { Helmet } from "react-helmet";
import { getSrc } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { I18nProvider, useI18n } from "@lib/i18n/i18n-react";
import { LocalizedRoutingProvider, Link } from "@lib/i18n/localized-routing";
// import { Link } from "@lib/i18n/localized-routing";
import { stripTrailingSlash, combinePaths, withTrailingSlash } from "@lib/utils/path-utils";
import { translations, languages, defaultLanguage } from "@translations";
import { optional, list, d, enumerate, formatDate } from "@lib/utils/translation-helpers";
import NavigationBar from "@components/navigation-bar";
import ErrorBoundary from "@components/error-boundary";
import Footer from "@components/footer";
import heartIcon from "@assets/feathericon/heart.svg";
import fireIcon from "@assets/icons/fire.svg";
import CookieDialog from "../cookie-dialog";
import gtag from "@lib/utils/gtag";
import "./index.scss";

const translationComponents = {
  strong: (text) => <strong>{text}</strong>,
  link: (href, text, options) => href.match(/^https?:\/\//)
    ? <a href={href} {...options}>{text}</a>
    : <Link to={href} {...options}>{text}</Link>,
  // Fallbacks for backend components
  optional,
  list,
  enumerate,
  d,
  formatDate,
  icons: {
    heart: (alt) => <img className="Layout__heartIcon" src={heartIcon} alt={alt} />,
    fire: (alt) => <img className="Layout__fireIcon" src={fireIcon} alt={alt} />
  },
}

const CookieConsent = function () {
  const { t } = useI18n("components.cookieConsent");

  const onInitCookies = useCallback(
    (isAccepted) => {
      if (isAccepted !== null) {
        gtag("consent", "update", { analytics_storage: isAccepted ? "granted" : "denied" });
      }
    },
    []
  );

  const onAcceptCookies = useCallback(
    () => {
      gtag("consent", "update", { analytics_storage: "granted" });
      // The page_view event is not automatically resent after consent is granted,
      // but we don't want to lose the original page view.
      gtag("event", "page_view");
    },
    []
  );
  const onRejectCookies = useCallback(
    () => gtag("consent", "update", { analytics_storage: "denied" }),
    []
  );

  return (
    <CookieDialog
      acceptText={t(".accept")}
      rejectText={t(".reject")}
      titleText={t(".title")}
      messageText={t(".message")}
      onInit={onInitCookies}
      onAccept={onAcceptCookies}
      onReject={onRejectCookies}
    />
  );
}

export default function Layout({ title, description = null, image = null, children }) {
  const { site: { siteMetadata: { title: siteTitle, siteVersion = "unspecified" } } } = useStaticQuery(graphql`
    query TitleQuery {
      site {
        siteMetadata {
          title,
          siteVersion,
        }
      }
    }
  `);

  const origin = process.env.GATSBY_SITE_URL;

  if(typeof window !== "undefined")
    console.info(`[SITE_VERSION]: ${siteVersion}`);

  return (
    <div className="Layout">
      <LocalizedRoutingProvider languages={languages} defaultLanguage={defaultLanguage} transformPath={withTrailingSlash}>
        {({ language, location: { pathname } }) =>
          <>
            <Helmet htmlAttributes={{ lang: language }}>
              <title>{title} | {siteTitle}</title>
              <meta property="og:title" content={title} />

              <meta name="note" content={`SITE_VERSION=${siteVersion}`} />

              {description !== null && description !== "" &&
                <meta name="description" content={description} />
              }
              {description !== null && description !== "" &&
                <meta property="og:description" content={description} />
              }

              {image !== null &&
                <meta property="og:image" content={`${origin}${getSrc(image)}`} />
              }

              {languages.filter(l => l !== language).map(lang => {
                return <link rel="alternate" hrefLang={lang} href={getLanguageUrl(origin, lang, pathname)} key={lang} />
              })}

              <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=2" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=2" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=2" />
              <link rel="manifest" href="/site.webmanifest?v=2" />
              <link rel="mask-icon" href="/safari-pinned-tab.svg?v=2" color="#02b05f" />
              <link rel="shortcut icon" href="/favicon.ico?v=2" />
              <meta name="msapplication-TileColor" content="#00aba9" />
              <meta name="theme-color" content="#ffffff" />
              <link rel="icon" type="image/svg+xml" href="/favicon.svg" />

            </Helmet>

            <I18nProvider
              language={language}
              translations={translations}
              defaultLanguage={defaultLanguage}
              components={translationComponents}>

              <NavigationBar />


              <ErrorBoundary fallback={(error) => (
                <div className="Layout__errorBoundary">
                  <h1>Sorry, something went wrong.</h1>
                  <p>We apologize for the inconvenience.</p>
                  <p>You can try refreshing the page or going to the <a href="/">home page</a>.</p>
                  <details>
                    <summary>Error details</summary>
                    <pre>{JSON.stringify(error, null, 2).split("\\n").join("\n  ")}</pre>
                  </details>
                </div>
              )}>

                <div className="Layout__contentWrapper">
                  {children}
                </div>

              </ErrorBoundary>

              <Footer />

              <CookieConsent />

            </I18nProvider>
          </>
        }
      </LocalizedRoutingProvider>
    </div>
  )
}

function getLanguageUrl(origin, lang, pathname) {
  return (lang === defaultLanguage)
    ? stripTrailingSlash(combinePaths(origin, pathname))
    : stripTrailingSlash(combinePaths(origin, lang, pathname));
}