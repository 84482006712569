const { languageNames, defaultLanguage } = require("./languages");

const translations = {};
for(let language of Object.keys(languageNames)) {
  translations[language] = require(`./${language}.js`);
}

const languages = Object.keys(translations);

module.exports.translations = translations;
module.exports.languages = languages;
module.exports.defaultLanguage = defaultLanguage;
module.exports.languageNames = languageNames;