import React from "react";
import { useI18n } from "@lib/i18n/i18n-react";
import { Link } from "@lib/i18n/localized-routing";
import "./index.scss";

export default function Footer() {
    const { t } = useI18n();

    return (
        <footer className="Footer">
            <div className="Footer__content">
                <div className="Footer__copyright">
                    {t("layout.copyright")}
                    {" "}
                    {t("layout.createdBy")}
                </div>

                <div className="Footer__links">
                    <Link className="Footer__link" to="/">{t("layout.activities")}</Link>
                    <Link className="Footer__link" to="/contact">{t("layout.contact")}</Link>
                    <Link className="Footer__link" to="/privacy">{t("layout.privacy")}</Link>
                </div>
            </div>
        </footer>
    );
}