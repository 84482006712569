const consts = require("../../lib/validations/validation-constants");

module.exports = ({ link, strong, icons, i, optional, list, d, capitalize, enumerate, formFieldsList, formatDate }) => {

  const layout = {
    logo: "Prague4Stags",
    activities: "Activities",
    about: "Our story",
    contact: "Contact us",
    privacy: "Privacy Policy",
    copyright: "Copyright © Prague4Stags.com.",
    createdBy: i`Created with ${icons.fire("passion")} by ${link("http://daviddostal.cz", "David Dostal", { target: "_blank" })}.`,
    logoLabel: "Logo",
    languageLabel: "Language",
    skipNavigation: "Skip navigation",
  };

  const components = {
    dropdown: {
      selectedLabel: "Selected option",
      optionsLabel: "Other options",
    },
    cookieConsent: {
      accept: "Accept cookies",
      reject: "Reject",
      title: "Cookie settings",
      message: i`We use cookies to anonymously measure site usage. For more details please refer to our ${link("/privacy", "privacy policy", { target: "_blank" })}.`,
    }
  };

  const activities = {
    categoryFilterLabel: "Filter by category",
    categoryAllKey: "all",
    filterCategories: {
      all: "All categories",
      outdoor: "Outdoor",
      indoor: "Indoor",
      beer: "Beer",
      accomodation: "Accomodation",
      food: "Food",
      transportation: "On the move",
      nightlife: "Nightlife",
      activities: "Activities",
    }
  };

  const activity = {
    activityInfoLabel: "Basic activity information",
    openingHours: "Opening hours",
    price: "Price from",
    location: "Location",
    questionsHeading: "Any Questions?",
    questionsText1: i`You can contact us at any time. If you have any questions or special requests, feel free to ${link("/contact", "leave us a message")} and we'll respond as soon as possible.`,
    questionsText2: i`Or you can ${strong("book this activity")} using the form above or ${link("/", "show all activities")} and look at our other fun options.`,
  };

  const contact = {
    heading: "Contact us",
    phone: "Phone",
    email: "Email",
    facebook: "Facebook",
    instagram: "Instagram",
    whatsApp: "WhatsApp",
    telegram: "Telegram",
    signal: "Signal",
    contactInfoLabel: "Contact information",
    contactFormLabel: "Contact form",
  };

  const bookingForm = {
    heading: "Book this activity",
    labels: {
      fullName: "Full name",
      email: "Your email",
      date: "Preferred date",
      time: "Preferred time",
      persons: "Number of persons",
      duration: "Duration",
      menu: "Menu variant",
      message: "Additional notes",
      messagePlaceholder: "If you have any additional notes, requests, questions etc., you can leave them here.",
      privacy: i`I have read and agree to the ${link("/privacy", "privacy policy", { target: "_blank" })}.`,
      submit: {
        initial: "Book activity",
        submitting: "Booking activity...",
        submitted: "Activity booked",
        invalid: "Book activity",
        error: "Try again",
      },
    },
    submitErrorMessage: ({ reason }) => `There was an error booking the activity (${reason}). Please try again.`,
    validations: {

      // Common inputs

      fullName: {
        required: "Full name is required.",
        minLength: ({ min }) => `Full name must be at least ${min} character(s) long.`,
        maxLength: ({ max }) => `Full name can be at most ${max} character(s) long.`,
      },
      email: {
        required: "Email is required.",
        format: "Please enter a valid email address",
        maxLength: ({ max }) => `Email address can be at most ${max} character(s) long.`,
      },
      date: {
        required: "Date is required.",
        format: "Please enter a valid date (yyyy-mm-dd).",
        min: ({ min }) => `Date must be ${min} or later.`,
        max: ({ max }) => `Date must be before ${max}.`,
      },
      message: {
        maxLength: ({ max }) => `Notes can be at most ${max} characters long.`,
      },
      privacy: {
        required: "You must agree to the privacy policy.",
      },
      time: {
        required: ({ label }) => `${label} is required.`,
        format: "Please enter a time in the format hh:mm.",
        min: ({ min }) => `The time must be ${min} or later.`,
        max: ({ max }) => `The time must be ${max} or sooner.`,
        step: ({ step }) => `The time must be in multiples of ${step} minutes.`,
      },
      select: {
        required: ({ label }) => `${label} is required.`,
        options: ({ opts }) => `Please enter one of the following values: ${enumerate(opts, "or")}.`,
      },
      number: {
        required: ({ label }) => `${label} is required.`,
        min: ({ min }) => `Please enter at least ${min}.`,
        max: ({ max }) => `Please enter at most ${max}.`,
        format: "Please enter an integer.",
      },
    },
  };

  const contactForm = {
    heading: "Leave a message",
    labels: {
      name: "Your name",
      email: "Email address",
      message: "Message",
      privacy: i`I have read and agree to the ${link("privacy", "privacy policy", { target: "_blank" })}.`,
      submit: {
        initial: "Send message",
        submitting: "Sending message...",
        submitted: "Message sent",
        invalid: "Send message",
        error: "Try again",
      },
    },
    submitErrorMessage: ({ reason }) => `There was an error sending the message (${reason}). Please try again.`,
    validations: {
      name: {
        required: "Name is required.",
        minLength: ({ min }) => `Name must be at least ${min} character(s) long.`,
        maxLength: ({ max }) => `Name can be at most ${max} character(s) long.`,
      },
      email: {
        required: "Email address is required.",
        format: "Please enter a valid email address",
        maxLength: ({ max }) => `Email address can be at most ${max} character(s) long.`,
      },
      message: {
        required: "Please enter a message.",
        minLength: ({ min }) => `Message must be at least ${min} character(s) long.`,
        maxLength: ({ max }) => `Message must fit within ${max} characters.`,
      },
      privacy: {
        required: "You must agree to the privacy policy.",
      }
    },
  };

  const antiSpam = {
    heading: "Please verify that you are a human:",
    answer: {
      label: 'Are you a spammer? (Please fill in "no".)',
      placeholder: 'Fill in "no".',
      validation: 'Please write "no" into this field.',
    },
    empty: {
      label: "Please leave this field empty",
      placeholder: "Leave this field empty.",
      validation: "Please leave this input empty.",
    }
  }

  const emailTemplates = {
    bookingMessage: ({ activity, fullName, email, date, message, privacy, ...formFields }) => ({
      subject: `Booking: ${activity} on ${formatDate("en", date)}`,
      text: d`
        Activity: ${activity}
        Full name: ${fullName}
        Date: ${formatDate("en", date)}
        ${formFieldsList(formFields, bookingForm.labels, Object.values(consts.common.antiSpam.inputs))}
        ${optional("Notes", message)}
      `
    }),
    bookingReceived: ({ activity, fullName, email, date, message, privacy, ...formFields }) => ({
      subject: `Your booking: ${activity} on ${formatDate("en", date)}`,
      text: d`
        Dear ${fullName},
        Thank you for your booking, we're glad to have you.

        Here are the booking details:

        Activity: ${activity}
        Date: ${formatDate("en", date)}
        ${formFieldsList(formFields, bookingForm.labels, Object.values(consts.common.antiSpam.inputs))}
        ${optional("Notes", message)}

        We'll get back to you soon to confirm the availability at the chosen date and time.

        Have a great day,
        The Prague4Stags team


        PS: If you have any questions, just reply to this email.
      `
    }),
    contactMessage: ({ name, email, message }) => ({
      subject: `Contact form: Message from ${name}`,
      text: message,
    }),
    contactReceived: ({ name, email, message }) => ({
      subject: `Prague4Stags: Message received`,
      text: d`
        Hello ${name},
        Thank you for contacting us, we have received your message and will get back to you shortly.

        Your message:
        "${message}"


        In the meantime have a great day!
        The Prague4Stags team


        PS: If you have any additional questions, just reply to this email.
      `
    }),
  };

  return {
    layout,
    components,
    activities,
    activity,
    contact,
    bookingForm,
    contactForm,
    antiSpam,
    emailTemplates,
  }
};
