function initTranslations(translations, components) {
    const appliedTranslations = {};
    for (let lang in translations) {
        const translation = translations[lang];
        appliedTranslations[lang] = (typeof translation === "function")
            ? translation({ i: interpolate, ...components })
            : translation;
    }
    return appliedTranslations;
}

function getTranslation(scope, name, language, translations, defaultLanguage) {
    const translationKey = name.startsWith(".") ? `${scope}${name}` : name;
    const translation = getProperty(translations[language], translationKey);

    if (translation !== null && translation !== undefined) {
        return translation;
    } else {
        console.error(`Missing translation for key '${scope}${name}' in language '${language}'.`);

        const defaultTranslation = getProperty(translations[defaultLanguage], translationKey);
        if (defaultTranslation !== null && defaultTranslation !== undefined) {
            return defaultTranslation;
        } else {
            console.error(`Missing default translation for key '${scope}${name}' in default language '${defaultLanguage}'.`);
            return `${scope}${name}`;
        }
    }
}

function getProperty(obj, path) {
    let value = obj;
    path.split(".").forEach(prop => {
        if (value === undefined) return null;
        value = value[prop];
    })
    return value;
}

function applyTranslation(translation, props, transformElements) {
    const translated = (typeof translation === "function")
        ? translation(props)
        : translation;

    return (Array.isArray(translated))
        ? transformElements(translated)
        : translated;
}

function interpolate(strings, ...values) {
    let result = [strings[0]];
    for (let i = 0; i < values.length; i++) { // strings.length is always values.length + 1
        result.push(values[i]);
        result.push(strings[i + 1]);
    }
    return result;
}

module.exports.initTranslations = initTranslations;
module.exports.getTranslation = getTranslation;
module.exports.applyTranslation = applyTranslation;
module.exports.i = interpolate;