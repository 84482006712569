import React from "react";
import { useI18n } from "@lib/i18n/i18n-react";
import arrowDown from "@assets/feathericon/arrow-down.svg";
import "./index.scss";

export default function Dropdown({ title, children, ...props }) {
    const { t } = useI18n("components.dropdown");

    return (
        <div className="Dropdown" {...props}>
            <div className="Dropdown__selected">
                {title(<img className="Dropdown__arrow" alt="" src={arrowDown} aria-hidden />)}
            </div>
            <ul className="Dropdown__items" aria-label={t(".optionsLabel")}>
                {children.map((child, index) =>
                    <li className="Dropdown__item" key={index}>{child}</li>
                )}
            </ul>
        </div>
    );
}