import React from "react";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorDetails: null };
    }

    static getDerivedStateFromError(error) {    // Update state so the next render will show the fallback UI.
        return { hasError: true, error: { ...error, name: error.name, message: error.message, stack: error.stack } };
    }

    componentDidCatch(error, errorInfo) {    // You can also log the error to an error reporting service    
        console.error(error, errorInfo);
        this.setState({ errorInfo });
    }

    render() {
        return (this.state.hasError)
            ? this.props.fallback({ error: this.state.error, errorInfo: this.state.errorInfo })
            : this.props.children;
    }
}
