const consts = require("../../lib/validations/validation-constants");

module.exports = ({ link, strong, icons, i, optional, list, d, capitalize, enumerate, formFieldsList, formatDate }) => {

  const layout = {
    logo: "Prague4Stags",
    activities: "Aktivity",
    about: "O nás",
    contact: "Kontakt",
    copyright: "Copyright © Prague4Stags.com.",
    createdBy: i`S ${icons.fire("nadšením")} vytvořil ${link("https://daviddostal.cz", "David Dostal", { target: "_blank" })}.`,
    privacy: "Ochrana osobních údajů",
    logoLabel: "Logo",
    languageLabel: "Jazyk",
    skipNavigation: "Přeskočit navigaci",
  };

  const components = {
    dropdown: {
      selectedLabel: "Vybraná možnost",
      optionsLabel: "Ostatní možnosti",
    },
    cookieConsent: {
      accept: "Přijmout cookies",
      reject: "Odmítnout",
      title: "Nastavení cookies",
      message: i`Tento web používá cookies pro anonymní analýzu návštěvnosti. Více informací naleznete v ${link("/privacy", "zásadách ochrany soukromí", { target: "_blank" })}.`,
    },
  };

  const activities = {
    categoryFilterLabel: "Filtrovat podle kategorie",
    categoryAllKey: "all",
    filterCategories: {
      all: "Všechny kategorie",
      outdoor: "Outdoorové aktivity",
      indoor: "Aktivity uvnitř",
      beer: "Pivo",
      accomodation: "Ubytování",
      food: "Jídlo",
      transportation: "V pohybu",
      nightlife: "Noční život",
      activities: "Aktivity",
    }
  };

  const activity = {
    activityInfoLabel: "Základní informace",
    openingHours: "Otevírací doba",
    price: "Cena od",
    location: "Místo",
    questionsHeading: "Máte otázky?",
    questionsText1: i`Kdykoli nám ${link("/contact", "napište nebo zavolejte")}. Jestli máte otázky nebo nějaké speciální požadavky, neváhejte nás kontaktovat a odpovíme Vám co nejdříve.`,
    questionsText2: i`Nebo si ${strong("objednejte tuto aktivitu")} či mrkněte na ${link("/", "další aktivity")} a vyberte si z nich.`,
  };

  const contact = {
    heading: "Kontakt",
    phone: "Telefon",
    email: "Email",
    facebook: "Facebook",
    instagram: "Instagram",
    whatsApp: "WhatsApp",
    telegram: "Telegram",
    signal: "Signal",
    contactInfoLabel: "Kontaktní informace",
    contactFormLabel: "Kontaktní formulář",
  };

  const bookingForm = {
    heading: "Objednat zážitek",
    labels: {
      fullName: "Jméno a příjmení",
      email: "Váš email",
      date: "Preferované datum",
      time: "Preferovaný čas",
      persons: "Počet účastníků",
      duration: "Délka hry",
      duration_values: {
        "1h": "1 hodina",
        "2h": "2 hodiny",
        ">2h": "přes 2h",
      },
      message: "Poznámky",
      messagePlaceholder: "Jestli máte další otázky, vzkazy, speciální požadavky apod., můžete je napsat sem.",
      privacy: i`Přečetl jsem si a souhlasím se ${link("/privacy", "zásadami ochrany soukromých údajů", { target: "_blank" })}.`,
      submit: {
        initial: "Odeslat objednávku",
        submitting: "Odesílám objednávku...",
        submitted: "Aktivita objednána",
        invalid: "Znovu odeslat",
        error: "Zkusit znovu",
      },
    },
    submitErrorMessage: ({ reason }) => `Nastal problém s odesláním objednávky (${reason}). Prosím zkuste to znovu.`,
    validations: {
      fullName: {
        required: "Jméno a příjmení je povinná položka.",
        minLength: ({ min }) => `Jméno a příjmení musí mít délku alespoň ${min} znak(ů).`,
        maxLength: ({ max }) => `Jméno a příjmení může mít nejvýše ${max} znak(ů).`,
      },
      email: {
        required: "Email je povinná položka.",
        format: "Prosím zadejte platnou emailovou adresu.",
        maxLength: ({ max }) => `Emailová adresa může mít nejvýše ${max} znak(ů).`,
      },
      persons: {
        required: "Počet účastníků je povinný.",
        min: ({ min }) => `Počet účastníků musí být alespoň ${min}.`,
        max: ({ max }) => `Počet účastníků může být nejvýše ${max}. Pokud potřebujete více, kontaktujte nás a rádi Vám připravíme nabídku na míru.`,
        format: "Počet účastníků musí být celé číslo.",
      },
      date: {
        required: "Preferované datum je povinné.",
        format: "Prosím zadejte platné datum (yyyy-mm-dd).",
        min: ({ min }) => `Preferované datum musí být ${min} nebo později.`,
        max: ({ max }) => `Preferované datum musí být před ${max}.`,
      },
      time: {
        required: "Preferovaný čas je povinná položka.",
        format: "Prosím zadejte čas ve formátu (hh:mm).",
        min: ({ min }) => `Čas může být nejdříve ${min}.`,
        max: ({ max }) => `Čas může být nejpozději ${max}.`,
        step: ({ step }) => `Čas musí být v násobcích ${step} minut.`,
      },
      message: {
        maxLength: ({ max }) => `Maximální délka poznámek je ${max} znaků.`,
      },
      privacy: {
        required: "Musíte souhlasit se zásadami ochrany soukromí.",
      },
      duration: {
        required: "Prosím zadejte požadovanou délku.",
        options: ({ opts }) => `Prosím zadejte jednu z následujících hodnot: ${enumerate(opts, "nebo")}.`,
        // min: ({ min }) => `Vybraná délka musí být nejméně ${min} hodin.`,
        // max: ({ max }) => `Vybraná délka může být nejvýše ${max} hodin.`,
      },
    },
  };

  const contactForm = {
    heading: "Napište nám",
    labels: {
      name: "Vaše jméno",
      email: "Váš email",
      message: "Vaše zpráva",
      privacy: i`Přečetl jsem si a souhlasím se ${link("/privacy", "zásadami ochrany soukromých údajů", { target: "_blank" })}.`,
      submit: {
        initial: "Odeslat zprávu",
        submitting: "Odesílám zprávu...",
        submitted: "Zpráva odeslána",
        invalid: "Znovu odeslat",
        error: "Zkusit znovu",
      },
    },
    submitErrorMessage: ({ reason }) => `Nastal problém s odesláním zprávy (${reason}). Prosím zkuste to znovu.`,
    validations: {
      name: {
        required: "Jméno je povinná položka.",
        minLength: ({ min }) => `Jméno musí být dlouhé alespoň ${min} znak(ů).`,
        maxLength: ({ max }) => `Maximální délka jména je ${max} znaků.`,
      },
      email: {
        required: "Email je povinná položka.",
        format: "Prosím zadejte platnou emailovou adresu.",
        maxLength: ({ max }) => `Emailová adresa může mít nejvýše ${max} znak(ů).`,
      },
      message: {
        required: "Zpráva je povinná položka",
        minLength: ({ min }) => `Zpráva musí být dlouhá alespoň ${min} znak(ů).`,
        maxLength: ({ max }) => `Maximální délka zprávy je ${max} znaků.`,
      },
      privacy: {
        required: "Musíte souhlasit se zásadami ochrany soukromí.",
      },
    },
  };

  const antiSpam = {
    heading: "Prosím potvrďte, že nejste robot:",
    answer: {
      label: 'Jste spammer? (Prosím vyplňte "ne".)',
      placeholder: 'Zde napište "ne".',
      validation: 'Prosím napište "ne" do tohoto políčka.',
    },
    empty: {
      label: "Toto políčko nechte prázdné",
      placeholder: "Zde nic nevyplňujte.",
      validation: "Prosím nechte toto políčko prázdné.",
    }
  }

  const emailTemplates = {
    bookingMessage: ({ activity, fullName, email, date, time, persons, message, privacy, ...formFields }) => ({
      subject: `Objednávka: ${activity} na ${formatDate("cs", date)}`,
      text: d`
        Aktivita: ${activity}
        Jméno a příjmení: ${fullName}
        Datum: ${formatDate("cs", date)}
        Čas: ${time}
        Počet osob: ${persons}
        ${formFieldsList(formFields, bookingForm.labels, Object.values(consts.common.antiSpam.inputs))}
        ${optional("Poznámky", message)}
      `
    }),
    bookingReceived: ({ activity, fullName, email, date, time, persons, message, privacy, ...formFields }) => ({
      subject: `Vaše objednávka: ${activity} na ${formatDate("cs", date)}`,
      text: d`
        Dobrý den,
        děkujeme za Vaši objednávku.

        Níže jsou podrobnosti objednávky:

        Aktivita: ${activity}
        Datum: ${formatDate("cs", date)}
        Čas: ${time}
        Počet osob: ${persons}
        ${formFieldsList(formFields, bookingForm.labels, Object.values(consts.common.antiSpam.inputs))}
        ${optional("Poznámky", message)}

        Brzy se Vám ještě ozveme, abychom potvrdili dostupnost ve vybrané datum a čas.

        S přáním hezkého dne,
        tým Prague4Stags


        PS: Máte-li jakékoli další dotazy, stačí odpovědět na tento email.
      `
    }),
    contactMessage: ({ name, email, message }) => ({
      subject: `Kontaktní formulář: Zpráva od ${name}`,
      text: message,
    }),
    contactReceived: ({ name, email, message }) => ({
      subject: `Prague4Stags: Zpráva přijata`,
      text: d`
        Dobrý den,
        děkujeme za Vaší zprávu, budeme se snažit Vám na ni co nejdříve odpovědět.

        Vaše zpráva:
        "${message}"
        

        S pozdravem,
        tým Prague4Stags


        PS: Máte-li nějaké další otázky, tak stačí odpovědět na tento email.
      `
    }),
  };

  return {
    layout,
    components,
    activities,
    activity,
    contact,
    bookingForm,
    contactForm,
    antiSpam,
    emailTemplates,
  }
}
