import React, { createContext, useContext } from "react";
import { Link as GatsbyLink, navigate as gatsbyNavigate } from "gatsby";
import { Redirect as GatsbyRedirect } from "@reach/router";
import { useLocation } from "@reach/router";
import { combinePaths } from "@lib/utils/path-utils";

const LocalizedRoutingContext = createContext(null);
LocalizedRoutingContext.displayName = "LocalizedRouting";

export function LocalizedRoutingProvider({ languages, defaultLanguage, children, transformPath = path => path }) {
  const fullLocation = useLocation();
  const { language, languagePath, shortPath } = splitLocalizedPath(fullLocation.pathname, languages, defaultLanguage);

  // Split original pathname into languagePath and rest of pathName
  const location = { ...fullLocation, languagePath, pathname: shortPath };


  const contextValue = {
    location,
    language,
    languages,
    defaultLanguage,
    transformPath,
  }

  return (
    <LocalizedRoutingContext.Provider value={contextValue}>
      {(typeof children === "function")
        ? children(contextValue)
        : children
      }
    </LocalizedRoutingContext.Provider>
  );
}

export function useLocalizedRouting() {
  const context = useContext(LocalizedRoutingContext);
  if (context === null)
    console.error(`The 'useLocalizedRoutes' hook and localized components (Link) must be used inside a LocalizedRoutingProvider.`);
  return context;
}

export function useRelativeLocation({ to, toLanguage }) {
  const { location: currentLocation, defaultLanguage, languages, transformPath } = useLocalizedRouting();

  if (toLanguage !== undefined && !languages.includes(toLanguage))
    console.warn(`Language '${toLanguage}' is not a valid language. Valid languages are: [${languages.join(", ")}].`);

  const fromLocation = {
    pathname: currentLocation.pathname,
    languagePath: currentLocation.languagePath,
    // If the path changes, we ignore the hash and search of the current location.
    // (When we navigate to a different page, we don't want to preserve the original
    // hash and search.)
    search: (to === undefined) ? currentLocation.search : "",
    hash: (to === undefined) ? currentLocation.hash : "",
  }

  const toLocation = parseUrl(to === undefined ? "" : transformPath(to));

  const newLocation = {
    ...mergeLocations(fromLocation, toLocation),
    languagePath: (toLanguage === undefined)
      ? fromLocation.languagePath
      : (toLanguage === defaultLanguage) ? "/" : transformPath(`/${toLanguage}`),
  }
  return newLocation;
}

export function useNavigate({ to, toLanguage, ...props }) {
  const newLocation = useRelativeLocation({ to, toLanguage });
  return () => gatsbyNavigate(locationToPath(newLocation), props);
}

export function Link({ to, toLanguage, children, target, ...props }) {
  const newLocation = useRelativeLocation({ to, toLanguage });
  if (target === "_blank") {
    return <a href={locationToPath(newLocation)} {...props} target={target}>{children}</a>;
  } else
    return <GatsbyLink to={locationToPath(newLocation)} {...props} target={target}>{children}</GatsbyLink>;
}
Link.displayName = "LocalizedRouting.Link";

export function Redirect({ to, toLanguage, ...props }) {
  const newLocation = useRelativeLocation({ to, toLanguage });
  return <GatsbyRedirect to={locationToPath(newLocation)} {...props} noThrow />;
}
Redirect.displayName = "LocalizedRouting.Redirect";

function splitLocalizedPath(path, languages, defaultLanguage) {
  const nonDefaultLanguages = languages.filter(l => l !== defaultLanguage);
  const match = nonDefaultLanguages.find(lang => path.startsWith(`/${lang}`));
  const language = match === undefined ? defaultLanguage : match;
  const languagePath = language === defaultLanguage ? "/" : `/${language}`;
  const shortPath = path.slice(languagePath.length);

  return { language, languagePath, shortPath };
}

function mergeLocations(oldLocation, newLocation) {
  const updateValue = (oldValue, newValue) => (newValue !== undefined && newValue !== "") ? newValue : oldValue;
  const merged = {};
  for (let key of Object.keys(oldLocation)) {
    merged[key] = updateValue(oldLocation[key], newLocation[key]);
  }
  return merged;
}

export function parseUrl(url) {
  // We don't care about the domain part, but want to use URL() for parsing relative paths.
  let { pathname, hash, search } = new URL(url, "http://example.com/");

  // Prevent starting slash inserted by URL even if there is none.
  if (!url.startsWith("/") && pathname.startsWith("/"))
    pathname = pathname.slice(1);
  return { pathname, hash, search };
}

export function locationToPath({ languagePath, pathname, search, hash }) {
  const fullPath = combinePaths(languagePath, pathname === "/" ? "" : pathname);
  // TODO: ? before search?
  return `${fullPath}${search}${hash}`;
}