import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import "./index.scss";

function parseBoolean(value) {
    if (typeof (value) === "string") {
        switch (value.toLowerCase()) {
            case "true": return true;
            case "false": return false;
            default: return null;
        }
    } else {
        return null;
    }
}

export default function CookieDialog({
    acceptText,
    rejectText,
    titleText,
    messageText,
    onAccept = () => { },
    onReject = () => { },
    onInit = (_) => { },
    storageKey = "CookieDialog.accepted",
    storage = typeof (window) !== "undefined" ? window.sessionStorage : undefined,
}) {
    const [isClosed, setIsClosed] = useState(true);
    
    useEffect(() => {
        const doNotTrack = window.navigator.doNotTrack === "1";
        if(doNotTrack) {
            setIsClosed(true);
            onInit(false);
        } else {
            const isAccepted = parseBoolean(storage.getItem(storageKey));
            setIsClosed(isAccepted !== null);
            onInit(isAccepted);
        }
    }, [storage, storageKey, onInit]);

    function handleAcceptClicked() {
        storage.setItem(storageKey, true);
        setIsClosed(true);
        onAccept();
    }

    function handleRejectClicked() {
        storage.setItem(storageKey, false);
        setIsClosed(true);
        onReject();
    }

    return (
        <div
            className="CookieDialog"
            hidden={isClosed}
            role="dialog"
            aria-modal="false"
            aria-label={titleText}
            aria-describedby="cookie-dialog-message"
        >
            <div className="CookieDialog__content">
                <div className="CookieDialog__text">
                    <p className="CookieDialog__message" id="cookie-dialog-message">{messageText}</p>
                </div>
                <div className="CookieDialog__buttons">
                    <button className="CookieDialog__acceptButton" onClick={handleAcceptClicked}>{acceptText}</button>
                    <button className="CookieDialog__rejectButton" onClick={handleRejectClicked}>{rejectText}</button>
                </div>
            </div>
        </div>
    );
}