import React, { useContext, createContext } from "react";
import { getTranslation, applyTranslation, initTranslations } from "./i18n";

const I18nContext = createContext(null);
I18nContext.displayName = "I18n";

export function I18nProvider({ language, translations, children, defaultLanguage, components = {} }) {
  return (
    <I18nContext.Provider value={{ language, translations: initTranslations(translations, components), defaultLanguage, components }}>
      {children}
    </I18nContext.Provider>
  );
};

export function I18nConsumer({ children, scope = "" }) {
  const i18n = useI18n(scope);
  if (typeof children === "function") {
    return children(i18n);
  } else {
    console.error("Children of I18nConsumer must be a function.");
    return null;
  }
}

export function T({ name, ...props }) {
  const { t } = useI18n();
  return t(name, props);
}

export function useI18n(scope = "") {
  const i18nContext = useContext(I18nContext);
  if (i18nContext === null) {
    console.error("You can call useI18n() only from components inside an I18nProvider.");
    return;
  }

  const { language, translations, defaultLanguage } = i18nContext;

  const t = (name, props) => {
    const translation = getTranslation(scope, name, language, translations, defaultLanguage);
    return applyTranslation(translation, props, addKeysToElements);
  }

  return { t, language, };
};

function addKeysToElements(elements) {
  return elements.map((element, i) => {
    if (React.isValidElement(element)) {
      return React.cloneElement(element, { key: i });
    } else {
      return element;
    }
  })
}

