import React from "react";

export default function gtag(...args) {
  if (typeof window !== "undefined" && window.gtag !== undefined)
    return window.gtag(...args);
}

export function gtagComponents(trackingId, { config, consent }) {
  return [
    <script key={`gtag-script`} async
      src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`}
    />,

    <script key={`gtag-config`} dangerouslySetInnerHTML={{
      __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              
              gtag('consent', 'default', ${JSON.stringify(consent)});
              gtag('config', '${trackingId}', ${JSON.stringify(config)});
          ` }}
    />,
  ];
}

export const gtagDefaults = {
  config: { anonymize_ip: true, },
  consent: {
    ad_storage: "denied",
    analytics_storage: "denied",
    wait_for_update: 1,
  },
}
