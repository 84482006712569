function splitAtFirstIndexOf(str, needle) {
  const firstIndex = str.indexOf(needle);
  const needleStart = firstIndex === -1 ? 0 : firstIndex;
  const needleEnd = firstIndex === -1 ? 0 : needleStart + needle.length;
  return [str.slice(0, needleEnd), str.slice(needleEnd, Infinity)];
}

function splitAtLastIndexOf(str, needle) {
  const lastIndex = str.lastIndexOf(needle);
  const needleStart = lastIndex === -1 ? 0 : lastIndex;
  const needleEnd = lastIndex === -1 ? needleStart : needleStart + needle.length;
  return [str.slice(0, needleEnd), str.slice(needleEnd)];
}

function stripTrailingSlash(path) {
  if (path === "/") return path;
  return path.endsWith("/") ? path.slice(0, -1) : path;
}

function withTrailingSlash(path) {
  return path.endsWith("/") ? path : path + "/";
}

function stripLeadingSlash(path) {
  if (path === "/") return path;
  return path.startsWith("/") ? path.slice(1, Infinity) : path;
}

function stripSlashes(path) {
  return stripTrailingSlash(stripLeadingSlash(path));
}

function _combineTwoPaths(basePath, path) {
  if (basePath === "") return path;
  if (path === "") return basePath;
  const baseTrimEndSlash = basePath.endsWith("/") ? basePath.slice(0, -1) : basePath;
  const pathTrimStartSlash = path.startsWith("/") ? path.slice(1) : path;
  return `${baseTrimEndSlash}/${pathTrimStartSlash}`;
}

function combinePaths(basePath, ...paths) {
  let combinedPath = basePath;
  for (let path of paths)
    combinedPath = _combineTwoPaths(combinedPath, path);
  return combinedPath;
}

module.exports.splitAtFirstIndexOf = splitAtFirstIndexOf;
module.exports.splitAtLastIndexOf = splitAtLastIndexOf;
module.exports.stripTrailingSlash = stripTrailingSlash;
module.exports.stripLeadingSlash = stripLeadingSlash;
module.exports.stripSlashes = stripSlashes;
module.exports.withTrailingSlash = withTrailingSlash;
module.exports.combinePaths = combinePaths;