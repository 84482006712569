import React from "react";
import Dropdown from "@components/dropdown";
import globeIcon from "@assets/feathericon/globe.svg";
import { useI18n } from "@lib/i18n/i18n-react";
import { Link } from "@lib/i18n/localized-routing";
import { languages, languageNames } from "@translations";
import "./index.scss";

export default function NavigationBar() {
    const handleTitleClick = (event) => {
        event.preventDefault();
    }

    const { t, language: currentLanguage } = useI18n();
    const isMultiLanguage = languages.length > 1;

    return (
        <div className="NavigationBar">
            <a className="NavigationBar__skipNavigation" href="#main">{t("layout.skipNavigation")}</a>

            <nav className="NavigationBar__content">
                <Logo />

                <Menu />

                {isMultiLanguage &&
                    <div className="NavigationBar__options" aria-label={t("layout.languageLabel")}>
                        <Dropdown title={(dropdownArrow) =>
                            <Link
                                className="NavigationBar__currentLanguage"
                                toLanguage={currentLanguage}
                                key={currentLanguage}
                                onClick={handleTitleClick}
                                aria-label={t("components.dropdown.selectedLabel")}
                            >
                                <img className="MenuDropdown__icon" alt="" src={globeIcon} aria-hidden />
                                {languageNames[currentLanguage]}
                                {dropdownArrow}
                            </Link>
                        }>
                            {languages.filter(l => l !== currentLanguage).map(language => (
                                <Link className="NavigationBar__languageLink" toLanguage={language} key={language}>
                                    {languageNames[language]}
                                </Link>
                            ))}
                        </Dropdown>
                    </div>
                }
            </nav>
        </div >
    );
}

function Menu() {
    const { t } = useI18n();
    return (
        <ul className="Menu">
            <li><Link to="/" className="Menu__link">{t("layout.activities")}</Link></li>
            <li><Link to="/contact" className="Menu__link">{t("layout.contact")}</Link></li>
        </ul>
    );
}

function Logo() {
    const { t } = useI18n();
    return (
        <Link className="NavigationBar__logo" to="/" aria-label={t("layout.logoLabel")}>
            <span className="Logo__prague">Prague</span><span className="Logo__4">4</span><span className="Logo__stags">Stags</span><span className="Logo__dotCom">.com</span>
        </Link>
    );
}